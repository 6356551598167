<template>
  <svg
    width="35"
    height="35"
    viewBox="0 0 1200 1200"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill="currentColor"
        d="M6.5,600.1c1.1-275,195.4-515.9,464.9-571.6C672.5-13,852,34.2,1004.9,172c105.6,95.2,167.9,215.6,184.1,356.9   c20.8,180.8-31.5,339.5-156.5,472.3c-95,101-212.7,161.4-350,179c-188.6,24.1-354.7-28.2-493.9-158.8   C87.9,926.8,29.8,809.2,11.7,672C8.6,648.3,8.2,624.1,6.5,600.1z M67.1,631.2C68.7,744.5,119.7,869,221.7,971   c92.4,92.3,204.9,144.4,335.5,154.1c163.9,12.2,306.4-39,422.4-154.9C1095.3,854.7,1146,713,1131.2,549.6   c-11.4-125.9-63.1-234.3-153.7-321.8C843.8,98.9,683.2,50.8,500.2,82.8C251.4,126.2,67.1,347.3,67.1,631.2z"
      />
      <path
        fill="currentColor"
        d="M999.7,820.7c-261.6,0-522.3,0-785.6,0c12.1-10.1,22.3-19.3,33.2-27.5c60-44.5,89.2-105.8,95.6-178.4   c4.5-50.1,5.4-100.5,8.5-150.7c2.7-45.4,11.8-89.5,33-130.2c34.4-66.1,90.2-103.2,162.2-117.7c3.6-0.7,7.2-1.6,10.7-2.3   c3.6-34.3,26-49,52.8-48.3c27.4,0.7,46.1,20.1,48.1,47.7c13.2,3.3,26.7,6,39.9,10.2c85.3,26.8,134.3,86.7,155.9,171.6   c12.3,48.4,10.6,97.8,12.2,147c1.3,40.8,5.6,81.2,15.1,121.1c13.5,57,47.2,100,92.8,134.9c8.4,6.4,16.3,13.4,24.4,20.2   C998.9,818.5,999.1,819.2,999.7,820.7z"
      />
      <path
        fill="currentColor"
        d="M503.2,862.4c70.2,0,140.5,0,210.8,0c-2.2,41.4-45.9,91.7-110.2,89.1C550.8,949.2,501.4,904.7,503.2,862.4z"
      />
    </g>
  </svg>
</template>
